.sub{
    background-color: white;
    justify-content: space-evenly;
    display: flex;
    margin: 1vh 0vw 1vh 0vw;
    padding: 1vh 0vw 1vh 0vw;
    display: grid;
    grid-column-gap: 50px;
    grid-template-columns: 15vw 10vw 10vw 10vw 10vw;
   box-shadow: rgb(145 158 171 / 24%) 0px 0px 2px 0px,
  rgb(145 158 171 / 24%) 0px 16px 32px -4px;
  border-radius: 20px;
}
.main{
    font-size: 3vw;
    background-color: black;
    color: white;
    padding: 1vh 1vw 1vh 0vw;
    border-radius: 10px;
}
.main p{
    margin: 0;
}
.name{
    font-size: 1.2vw;
    font-weight: 600;
}
.name p{
    margin: 4vh 0vw 0vh 0vw;
}
.cell{
    background-color: white;
   border: 1px dashed grey;
    margin: auto;

}