.main{
    background-color: white;
    margin: 10vh auto 0vh auto;
    width: 50%;
    background-color: yellow;
    border-radius: 20px;
    padding: 2vh 2vw 2vh 2vw;
    .partner{
        display: block;
    }
    .salary{
        display: block;
    }
    .payday{
        display: block;
    }
}
.radio{
    display:block !important;
}