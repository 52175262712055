.imgholder {
  border: 5px solid black;
}

.detailArrow {
  color: black;
  font-size: 2rem;
  position: absolute;
  top: 1vh;
  right: 5px;
  cursor: pointer;
}
