.title {
  background-color: black;
  padding: 10px;
  font-size: 1.5vw;
  font-weight: 600;
  color: white;
  text-align: center;
}
.table {
  min-height: 10vh;
  border-radius: 10px;
  border: 1px solid gray;
  margin-top: 20px;
  .head {
    background-color: rgb(243, 198, 55);
    border: 1px solid black;
    display: grid;
    padding: 2vh 0vw 2vh 0vw;
    grid-template-columns: 20% 20% 20% 20% 20%;
    .Hcell {
      font-size: 1.1vw;
      font-weight: 600;
      text-align: center;
    }
  }
  .body {
    .row {
      display: grid;
   grid-template-columns: 20% 20% 20% 20% 20%;
      border: 1px solid gray;
      padding: 2vh 0vw 2vh 0vw;
      .cell {
        font-size: 1vw;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}
.modaltable {
  width: 80%;
  position: fixed;
  top: 10vh;
  right: 11vw;
  min-height: 10vh;
  border-radius: 10px;
  max-height: 60vh;
  overflow-y: auto;
  border: 1px solid gray;
  margin-top: 10vh;
  box-shadow: rgb(145 158 171 / 24%) 0px 0px 2px 0px,
    rgb(145 158 171 / 24%) 0px 16px 32px -4px;
  .head {
    background-color: rgb(243, 198, 55);
    border: 1px solid black;
    display: grid;
    padding: 2vh 0vw 2vh 0vw;
   grid-template-columns: 20% 20% 20% 20% 20%;
    .Hcell {
      font-size: 1.1vw;
      font-weight: 600;
      text-align: center;
    }
  }
  .body {
    .row {
      display: grid;
     grid-template-columns: 20% 20% 20% 20% 20%;
      border: 1px solid gray;
      padding: 2vh 0vw 2vh 0vw;
      background-color: white;
      .cell {
        font-size: 1vw;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}

.table2 {
  min-height: 10vh;
  border-radius: 10px;
  border: 1px solid gray;
  margin-top: 10vh;
  .head {
    background-color: rgb(243, 198, 55);
    border: 1px solid black;
    display: grid;
    padding: 2vh 0vw 2vh 0vw;
    grid-template-columns: 14% 14% 14% 14% 14% 14% 14%;
    .Hcell {
      font-size: 1.1vw;
      font-weight: 600;
      text-align: center;
    }
  }
  .body {
    .row {
      display: grid;
   grid-template-columns: 14% 14% 14% 14% 14% 14% 14%;
      border: 1px solid gray;
      padding: 2vh 0vw 2vh 0vw;
      .cell {
        font-size: 1vw;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}
.modaltable2 {
  width: 80%;
  position: fixed;
  top: 10vh;
  right: 11vw;
  min-height: 10vh;
  border-radius: 10px;
  max-height: 60vh;
  overflow-y: auto;
  border: 1px solid gray;
  margin-top: 10vh;
  box-shadow: rgb(145 158 171 / 24%) 0px 0px 2px 0px,
    rgb(145 158 171 / 24%) 0px 16px 32px -4px;
  .head {
    background-color: rgb(243, 198, 55);
    border: 1px solid black;
    display: grid;
    padding: 2vh 0vw 2vh 0vw;
   grid-template-columns: 14% 14% 14% 14% 14% 14% 14%;
    .Hcell {
      font-size: 1.1vw;
      font-weight: 600;
      text-align: center;
    }
  }
  .body {
    .row {
      display: grid;
     grid-template-columns: 14% 14% 14% 14% 14% 14% 14%;
      border: 1px solid gray;
      padding: 2vh 0vw 2vh 0vw;
      background-color: white;
      .cell {
        font-size: 1vw;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}