.debtDetails {
  display: grid;
  grid-template-columns: auto auto;
  padding: 1rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 50px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  div {
    margin: 10px;
  }
  .sellinvoice {
    justify-content: end;
    gap: 8px;
    display: flex;
  }
  .borrowing {
    justify-content: start;
    gap: 8px;
    display: flex;
  }
  .truckinvoice {
    justify-content: end;
    gap: 8px;
    display: flex;
  }
  .advance {
    justify-content: start;
    gap: 8px;
    display: flex;
    margin-right: -2.5rem;
  }
}
.grid2 {
  padding: 1rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 2vh;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  .tools {
    justify-content: end;
    gap: 8px;
    display: flex;
    margin-right: 3rem;
  }
  .salary {
    justify-content: end;
    gap: 8px;
    display: flex;
  }
  .morta {
    justify-content: start;
    gap: 8px;
    display: flex;
    margin-left: 2rem;
  }
}
.grid21 {
  width: 70%;
  padding: 1vw;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 2vh;
  display: grid;
  grid-template-columns: auto;
  .tools {
    display: flex;
    justify-content: space-around;
    margin: 0vh 1vw 0vh 15vw;
  }
}
.root {
  flex-grow: 1;
  width: "100%";
  height: "100%";
}
.detailArrow {
  color: black;
  font-size: 2rem;
  cursor: pointer;
}
.filterIcons
{
  width: 185px !important;
  height: 40px !important;
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
  border-radius: 25px !important;
  background: white !important;
}