.demo {
  max-height: 50vh;
  overflow: hidden;
  
  
  /* transition: .5s ; */
}
.navitem {
 
  overflow: visible !important;
 
  
  
}
.titleHolder {
  background-color: yellow !important;
  margin-right: 40vw !important;
  z-index: 999;
  
}
.collapse-button {
  display: block;
  width: 10%;
  
 
}

.collapse-content.collapsed {
  display: none;
 
  
}

.collapsed-content.expanded {
  display: block;
  
}
.navholder {
  width: 5vw;
  height: auto;
  overflow: visible;
  margin: 3.3rem 0vw 0vh .5vw;
  
  box-shadow:rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
  padding-top: 12px;
}
.navholderexpand {
  width: 15vw;
  overflow: visible;
  margin: 0vh 0vw 0vh .5vw;
  height: auto;
  box-shadow:rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
  border: #070707 10xp solid;
  padding: 0vh 0vw 0vh 0vw;
}
.btn {
  padding: 2.5vh 1.5vw 1vh 1vw;
  height: 8vh;
  background: linear-gradient(#070707, #353434 50%, #070707);
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
  border: 1px solid #070707;
  border-radius: 5px;
  color: #fff;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1vw;
  transition: all 0.2s ease;

}

.btn:hover {
  background: linear-gradient(#070707, #514f4f 50%, #070707);
  box-shadow: rgb(0 0 0 / 60%) 0px 4px 8px, rgb(0 0 0 / 50%) 0px 10px 20px -5px, rgb(0 0 0 / 30%) 0px -4px 0px inset;
  transform: translateY(2px) scale(1.05);
  border: 1px solid #353434;
  color: #ddd;
}
.btn:active {
  background: linear-gradient( #27235c , #4a90e2 10% ,#27235c);
  border: 1px solid #4a90e2;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); 
  color: #ffffff; 
  transition: all .03s ease; 
}
.btn p {
  margin: 0vh 0vw 2vh 0vw;
  transition: all 0.3s ease;
}
.btn:hover.btn .iconHandler {
  color:#ffd900;
  
}

.btn:hover  .iconHandleractive svg{
  color:#ffd900;
  
}

.nestedopen {
  padding: 0vh 0vw 0vh 1vw;
  border-radius: 10px;
  position: absolute;
  right: 5vw;
  top: -7vh;
  width: 20vw;
  border-radius: 10px;
  max-height: 100vh;
  overflow: hidden;
  transition-duration: 1s;
  z-index: 100;
  
  
}
.open {
  max-height: 100vh;
  overflow: hidden;
  transition: .5s ease-out;
  
 
}
.collapse {
  max-height: 0;
  transition: .5s ease-out;
  
  
}

.nested {
  padding: 0vh 1vw 0vh 0vw;
  background-color: rgb(255, 218, 0);
  display: flex;
  box-shadow:rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
  border-radius: 10px;
  
  
  
}
.nested.active  {
  background: linear-gradient(to top, #f57542, #FFA500, #F3C637 70%, #FFD700, #FFD700,#FFD700, #FFA500);
  box-shadow:rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
  color: #181301; 
}
.nested.active .icon {
  background: radial-gradient(circle, #fff 0%, #efeee9b0);
  box-shadow: 0 0 20px 10px rgba(242, 236, 236, 0.827), 0 0 0 4px #e09970d0;
  border-radius: 10px;
  border: none;
  color: rgba(65, 65, 67, 0.079);
}

.nested:hover {
  background: linear-gradient(to top, #f57542, #FFA500, #F3C637 70%, #FFD700, #FFD700,#FFD700, #FFA500);
  box-shadow:rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
  cursor: pointer;
}

.icon {
  margin: 2.6vh 0vw 0vh 3vw;
  background: radial-gradient(circle,  rgb(78, 73, 46) , #0b0b0bb0 );
  box-shadow:0 0 20px 10px #dfc70ee4,0 0 0 4px #907c0957;
  border-radius: 10px ;
  border: #e1b802 2px solid;
  
}
.nested:hover .icon {
  background: radial-gradient(circle,  #fff 0%, #efeee9b0 );
  box-shadow: 0 0 20px 10px rgba(242, 236, 236, 0.827),0 0 0 4px #e09970d0;
  border-radius: 10px;
  border:none;
  color: rgba(65, 65, 67, 0.079);
  
}

.collapseholder {
  position: relative;
  transition: 1s ease-out;
  
  
}
.closemodal {
  position: absolute;
  top: 0;
  left: 2%;
  width: 65%;
  height: 120%;
  z-index: 200;
}
.iconHandleractive,
.iconHandler svg {
  width: 1em;
  height: 1em;
  font-size: 1.5rem;
}
@media only screen and (max-width: 1200px) {
  .navholder {
    width: auto;
    margin: 50px 0vw 0vh 1vw;
    padding-top: 15px;
    
  }
  .iconHandleractive svg {
    width: 70%;
    
    

  }
 
  .nestedopen {
    right: 100%;
    top: -4vh;
    width: 30vw;
  }
  .nested p {
    font-size: auto !important;
  }
 
  .btn {
    padding: 2vh 2vw 0vh 0vw;
    height: 60%;
  }
  .btn p {
    font-size: auto;
  }
  
  .iconHandler svg {
    width: 1em;
    height: 1em;
    font-size: 1.2rem;
  }
}


@media only screen and (max-width: 600px) {
  .navholder {
    width: 10vw;
    margin: 45px 0vw 0vh 1vw;
    padding-top: 12px;
    
  }
  .iconHandleractive svg {
    width: 5vw;
  }
  .iconHandler svg {
    width: 0;
    height: 0;
    display: none;
    overflow: hidden;
  }
  .btn {
    padding: 2vh 2vw 0vh 0vw;
    height: 6vh;
  }
  .btn p {
    font-size: 3vw;
  }

  .nestedopen {
    right: 10vw;
    top: -4vh;
    width: 35vw;
  }
  .nested p {
    font-size: 2.5vw !important;
  }
  .navholderexpand {
    width: 25vw;
  }
  .icon {
    margin: 1.8vh 0vw 0vh 3vw;
    font-size: 3vw;
  }
}
/* تكبير الأيقونات عند عرض الموبايل */
@media (max-width: 768px) {
  .avatar-menu .btn .icon {
    font-size: 2.5vw; /* حجم أكبر للأيقونات */
  }

  .avatar-menu .nested .icon {
    font-size: 2vw; /* حجم أكبر للأيقونات */
  }
}

/* تأثير الدخول والخروج */
.menu-enter {
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 3s ease, transform 3s ease;
}
.menu-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 3s ease, transform 3s ease;
}
.menu-exit {
  opacity: 1;
  transform: scale(1);
  transition: opacity 3s ease, transform 3s ease;
}
.menu-exit-active {
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 3s ease, transform 3s ease;
}

