.holder {
  display: flex;
  padding: 10px 8px;
  justify-content: space-between;
  margin: 1rem 0;
  border: 2px #000  dashed;

}
.main{
    display: flex;
    align-items: center;
    justify-content: center;
}
.cell{
    font-weight: bolder;
    font-size: 18px;
    color:black;
    margin:0 10px 0 0;
    padding: 0 5px 0 5px;
}
