.cntr {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 10px;
}

.h-88 {
  height: 75%;
}

.instruction-container {
  margin: auto;
  text-align: right;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  width: 100%;
}

.instruction-text {
  margin-bottom: 30px;
  font-size: 18px;
  color: #333;
  line-height: 1.8;
}

.button-container {
  display: flex;
  justify-content: center;
}

.backUp-button {
  padding: 15px 50px;
  font-size: 18px;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  background-image: linear-gradient(
    to top,
    #f54242,
    #d90505,
    #a80c0c 70%,
    #ab0707,
    #ab0707,
    #ab0707,
    #ab0707
  );
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.4), 0 7px 13px -3px rgba(0, 0, 0, 0.3),
    0 -3px 0px inset rgba(0, 0, 0, 0.2);
}

.backUp-button:hover {
  background-image: linear-gradient(
    to top,
    #f54242,
    #d90505,
    #a80c0c 70%,
    #d90505,
    #f54242
  );
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.4), 0 9px 15px -3px rgba(0, 0, 0, 0.3),
    0 -3px 0px inset rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.backUp-button:active {
  transform: translateY(2px) scale(0.98);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5), 0 4px 10px -2px rgba(0, 0, 0, 0.4),
    0 -2px 0px inset rgba(0, 0, 0, 0.3);
  background-image: linear-gradient(
    to top,
    #d90505,
    #a80c0c,
    #760909 70%,
    #570505,
    #570505,
    #570505,
    #570505
  );
  transition: transform 0.1s ease, box-shadow 0.1s ease,
    background-image 0.1s ease;
}

.backUp-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.backUp-button.loading {
  color: white;
  background-image: linear-gradient(
    to right,
    #04ee08 0%,
    #07a50c 50%,
    #ab0707 50%,
    #ea0f0f 100%
  );
  background-size: 200% 100%;
  background-position: right bottom;
  animation: loadingAnimation 2s infinite linear;
}

@keyframes loadingAnimation {
  from {
    background-position: right bottom;
  }
  to {
    background-position: left bottom;
  }
}

.backUp-button.completed {
  background-image: linear-gradient(
    to top,
    #42f54e,
    #09d905,
    #0ca80f 70%,
    #129409,
    #129409,
    #129409,
    #129409
  );
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .instruction-container {
    margin: auto;
    padding: 10px;
    font-size: 16px;
  }
  .instruction-text {
    font-size: 14px;
  }

  .backUp-button {
    padding: 12px 15px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .instruction-container {
    padding: 10px;
    margin: auto;
    font-size: 14px;
  }
  .instruction-text {
    font-size: 12px;
  }

  .backUp-button {
    padding: 10px 12px;
    font-size: 14px;
  }
}
