.container {
  h3 {
    margin: 1rem 1rem 3rem 0;
    color: black;
    font-size: 3rem;
  }
  .videoHolder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .videoComp {
      margin: 5px;
    }
  }
}
.downloadsHolder {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  > div {
    background: #b9b9b9a4;
    border-radius: 22px;
  }
  p {
    font-size: 2rem;
    font-weight: 600;
    margin: 1rem 0 0 0;
    color: #fff;
  }
  svg {
    font-size: 5rem;
    margin-bottom: 3rem;
  }
  .item1 {
    svg {
      color: red;
    }
  }
}
