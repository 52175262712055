.holder {
  display: flex;
  gap: 15px;
  margin-bottom: 3rem;
  align-items: center;
  flex-wrap: wrap;
}
.input {
  width: 15vw;
  height: 5vh;
  font-size: 1.4vw;
  margin: auto;
}

.time {
  display: flex;
  gap: 15px;
  margin: auto;
}
.btn {
  background-color: rgb(243, 198, 55);
  color: black;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
  border: 0;
  font-weight: bolder;
  padding: 0.6rem 1rem;
}
.btn:disabled {
  background-color: #aeb3b9;
  cursor: auto;
  color: white;
}
