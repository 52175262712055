@font-face {
  font-family: "DroidKufi-Regular";
  src: url("./assets/fonts/Almarai/Almarai-Bold.ttf");
}

@font-face {
  font-family: "DroidKufi-Bold";
  src: url("./assets/fonts/Almarai/Almarai-ExtraBold.ttf");
}

html,
body {
  margin: 0;
  height: "100vh";
  width: "100vw";
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

* {
  font-family: "DroidKufi-Regular", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #534d3b;
}

::-webkit-scrollbar-track:hover {
  background: #e1dfe9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b8bdc2;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a9aaac;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media print {
  body {
    background-color: #fff;
    direction: rtl;
    padding: 8px;
    margin: 0;
  }
}

a:link {
  text-decoration: none;
}

.scrollBtn {
  color: orange;
  position: fixed;
  top: 88vh;
  right: 0vw;
  cursor: pointer;
  font-size: 4vw;
  animation: up-down 2s infinite;
  -webkit-animation: up-down 2s infinite;
}

@keyframes up-down {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

.disable {
  font-size: 0;
  display: none;
  width: 0;
  height: 0;
}

.backUp {
  background-color: #f3c637;
  padding: 10px;
  border-radius: 20px;
  font-size: 20px;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.backUp:hover {
  background-color: #9f811d;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .scrollBtn {
    font-size: 8vw;
  }

  .arrowUPTo {
    height: "30px";
    width: "30px";
  }
}

.MuiButton-contained.Mui-disabled {
  background-color: rgb(218, 198, 198) !important;
  color: #807979 !important;
}

.PrivateSwitchBase-input-29,
.PrivateSwitchBase-input-44 {
  z-index: 0 !important;
}

.detailArrow {
  color: black;
  font-size: 2rem;
  cursor: pointer;
}

.margin15px {
  margin-top: -15px !important;
}

.btnMore {
  background: #343434 !important;
  color: white !important;
  height: 38px;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px,
    rgb(0 0 0 / 20%) 0px -3px 0px inset;
  padding: 15px;
  cursor: pointer;
}

.btnDelete {
  background: rgb(188, 68, 68) !important;
  color: white !important;
  height: 38px;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px,
    rgb(0 0 0 / 20%) 0px -3px 0px inset;
  padding: 15px;
}

.globalFont {
  font-size: 16.6px !important;
}

.rowHover:hover {
  cursor: pointer;
  background: #b5b3ae80;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px,
    rgb(0 0 0 / 20%) 0px -3px 0px inset;
}

.center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.hoverCard:hover {
  box-shadow: black 13px 13px 10px !important;
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
  transform-style: flat;
  transition-duration: 0.5s;
  -moz-transform: scale(1.04);
  -ms-transform: scale(1.03);
  -o-transform: scale(1.03);
}

.linkHover:hover {
  border-radius: 7px;
  padding: 0 3px;
  box-shadow: rgba(249, 247, 247, 0.17) 0px -23px 25px 0px inset,
    rgba(186, 172, 172, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

.bg-global {
  background: linear-gradient(
    to top,
    #f57542,
    #ffa500,
    #f3c637 70%,
    #ffd700,
    #ffd700,
    #ffd700,
    #ffa500
  ) !important;
  color: black !important;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px,
    rgb(0 0 0 / 20%) 0px -3px 0px inset;
  height: 38px;
  padding: 15px;
}
/* header */
.headerSearch {
  background-color: white;
  padding: 0 20px 20px 20px;
  border-radius: 15px;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px,
    rgb(0 0 0 / 20%) 0px -3px 0px inset !important;
  margin: 50px auto;
}

.globalHeadTable {
  background: "#FED049";
  height: "65px";
}

.hover-row:hover {
  transform: scaleY(1.1);
  background: #f4c33354 !important;
}

body {
  background-color: #534d3b !important;
}

.MuiInputLabel-outlined {
  z-index: 1;
  transform: translate(-14px, 20px) scale(1);
  pointer-events: none;
  color: black !important;
}

/* ================ */
.m-auto {
  margin: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.my-2 {
  margin-top: 12px;
  margin-bottom: 12px;
}
.mt-3 {
  margin-top: 1rem;
}
.mt-4 {
  margin-top: 1.5rem;
}
.mb-3 {
  margin-bottom: 1rem;
}

/* === */
.w-100 {
  width: 100%;
}
.w-75 {
  width: 75%;
}
.w-50 {
  width: 50%;
}
.h-100 {
  height: 100%;
}
/* === */
.p-0 {
  padding: 0;
}
.p-2 {
  padding: 8px;
}
.bg-transparent {
  background: transparent;
}
.bg-white {
  background: white;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.justify-content-start {
  justify-content: start;
}
.justify-content-between {
  justify-content: space-between;
}
.position-relative {
  position: relative;
}

.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}
.text-white {
  color: #fff;
}
.text-black {
  color: #000;
}

/* === */
.rounded-2 {
  border-radius: 2px;
}
.rounded-3 {
  border-radius: 3px;
}
.rounded-4 {
  border-radius: 4px;
}

/* ........end ............... */

/* ................................... end ........................ */
