.main {
  background-color: rgb(251, 191, 5);
  height: 100vh;
  padding: 2vw;
  h2 {
    text-align: center;
    margin-top: 6vh;
    font-size: 4vw;
  }
}
.key {
  padding: 4vh 3vw 4vh 3vw;
  width: 50%;
  background-color: rgb(165, 132, 30);
  border-radius: 10px;
  text-align: center;
  margin: 2vh 25vw 2vh 0vw;
  overflow: hidden;
  p {
    font-weight: 600;
  }
  button {
    background-color: black;
    color: white;
    cursor: pointer;
    padding: 2vh 2vw;
    border-radius: 10px;
    font-size: 1.5vw;
    &:hover {
      background-color: rgb(251, 191, 5);
      color: black;
    }
  }
}
.actvholder {
  margin: 10vh 3vw 0vh 0vw;
  input {
    height: 6vh;
    border-radius: 8px;
    font-size: 1.5vw;
    border: gray;
  }
  button {
    background-color: black;
    color: white;
    cursor: pointer;
    border-radius: 10px;
    padding: 1vh 2vw;
    font-weight: 500;
    font-size: 1.5vw;
    margin: 0vh 3vw 0vh 0vw;
    &:hover {
      background-color: rgb(251, 191, 5);
      color: white;
    }
  }
  .actvbtn{
    display: block;
    margin: 2vh 0vw 0vh 0vw;
  }
}

.main2{
  background-color: rgb(251, 191, 5);
  height: 100vh;
  padding: .1vw;
  h2 {
    text-align: center;
    margin-top: 30vh;
    font-size: 3.5vw;
  }
}