/* ................................... Ahmed Css Files ........................ */
/* ........seller page ............... */


.Calendar-container {
    width: 185px;
    height: 40px !important;
    justify-content: center !important;
    align-items: center !important;
    display: flex !important;
    border-radius: 25px !important;
    background: white !important;
    cursor: pointer;
    margin-bottom: 5px;

}

.filterIcons {
    width: 145px !important;
    height: 40px !important;
    justify-content: center !important;
    align-items: center !important;
    display: flex !important;
    border-radius: 25px !important;
    background: white !important;
    cursor: pointer;
    margin-bottom: 5px;
}

.showAll {
    width: 105px !important
}

.sellerpage .react-datepicker-wrapper {
    width: 185px !important;
    height: 40px !important;
    ;
}

.infoAboutSeller {
    padding: 12px;
    text-align: center;
    display: inline-block;
    margin: 0 5px 5px 5px;
    background-color: #FDF44D;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.billsInfo {
    height: 70px;
    background: #fff;
    border-radius: 9px;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 7px;
}


.btnMore {
    background: #343434 !important;
    color: white !important;
    height: 38px;
    box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
    padding: 15px;
}

.sellerpage .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
}



.font23px {
    font-size: 23px !important;
}


.makeStyles-content-11 {
}


.hover-link:hover
{
    box-shadow: black 13px 13px 10px !important;
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    transform-style: flat;
    transition-duration: 0.5s;
    -moz-transform: scale(1.04);
    -ms-transform: scale(1.03);
    -o-transform: scale(1.03);
}

.headerTableFont
{
    font-size: 16.6px !important;
}
.BodyTableFont
{
    font-size: 16.6px !important;
    height: 85px !important;

}

.react-datepicker__day-names {
    font-size: 10px;
    justify-content: space-evenly;
    display: flex;
    align-items: center;
}
