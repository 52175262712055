.table {
  min-height: 10vh;
  border-radius: 10px;
  border: 1px solid gray;
  margin-top: 10vh;
  .head {
    background-color: rgb(243, 198, 55);
    border: 1px solid black;
    display: grid;
    padding: 2vh 0vw 2vh 0vw;
    grid-template-columns: 12% 12% 12% 20% 12% 20% 12%;
    .Hcell {
      font-size: 1.1vw;
      font-weight: 600;
      text-align: center;
    }
  }
  .body {
    .row {
      display: grid;
      grid-template-columns: 12% 12% 12% 20% 12% 20% 12%;
      border: 1px solid gray;
      padding: 2vh 0vw 2vh 0vw;
      .cell {
        font-size: 1vw;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}
.modaltable {
  width: 80%;
  position: fixed;
  top: 10vh;
  right: 11vw;
  min-height: 10vh;
  border-radius: 10px;
  max-height: 60vh;
  overflow-y: auto;
  border: 1px solid gray;
  margin-top: 20px;
  box-shadow: rgb(145 158 171 / 24%) 0px 0px 2px 0px,
    rgb(145 158 171 / 24%) 0px 16px 32px -4px;
  .head {
    background-color: rgb(243, 198, 55);
    border: 1px solid black;
    display: grid;
    padding: 2vh 0vw 2vh 0vw;
    grid-template-columns: 12% 12% 12% 20% 12% 20% 12%;
    .Hcell {
      font-size: 1.1vw;
      font-weight: 600;
      text-align: center;
    }
  }
  .body {
    .row {
      display: grid;
      grid-template-columns: 12% 12% 12% 20% 12% 20% 12%;
      border: 1px solid gray;
      padding: 2vh 0vw 2vh 0vw;
      background-color: white;
      .cell {
        font-size: 1vw;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}

.table1 {
  min-height: 10vh;
  border-radius: 10px;
  border: 1px solid gray;
  margin-top: 10vh;

  .head {
    background-color: rgb(243, 198, 55);
    border: 1px solid black;
    display: grid;
    padding: 2vh 0vw 2vh 0vw;
    grid-template-columns: 9% 9% 9% 9% 9% 9% 9% 9% 9% 9% 10%;
    .Hcell {
      font-size: 1.1vw;
      font-weight: 600;
      text-align: center;
    }
  }
  .body {
    .row {
      display: grid;
      grid-template-columns: 9% 9% 9% 9% 9% 9% 9% 9% 9% 9% 10%;
      border: 1px solid gray;
      padding: 2vh 0vw 2vh 0vw;
      .cell {
        font-size: 1vw;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}
.modaltable1 {
  width: 80%;
  position: fixed;
  top: 10vh;
  right: 11vw;
  min-height: 10vh;
  border-radius: 10px;
  max-height: 60vh;
  overflow-y: auto;
  border: 1px solid gray;
  margin-top: 10vh;
  box-shadow: rgb(145 158 171 / 24%) 0px 0px 2px 0px,
    rgb(145 158 171 / 24%) 0px 16px 32px -4px;

  .head {
    background-color: rgb(243, 198, 55);
    border: 1px solid black;
    display: grid;
    padding: 2vh 0vw 2vh 0vw;
    grid-template-columns: 9% 9% 9% 9% 9% 9% 9% 9% 9% 9% 10%;
    .Hcell {
      font-size: 1.1vw;
      font-weight: 600;
      text-align: center;
    }
  }
  .body {
    .row {
      display: grid;
      grid-template-columns: 9% 9% 9% 9% 9% 9% 9% 9% 9% 9% 10%;
      border: 1px solid gray;
      padding: 2vh 0vw 2vh 0vw;
      background-color: white;
      .cell {
        font-size: 1vw;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}
.table2 {
  min-height: 10vh;
  border-radius: 10px;
  border: 1px solid gray;
  margin-top: 10vh;
  .head {
    background-color: rgb(243, 198, 55);
    border: 1px solid black;
    display: grid;
    padding: 2vh 0vw 2vh 0vw;
    grid-template-columns: 7% 8% 7% 7% 7% 7% 7% 7% 7% 7% 7% 7% 8% 7%;
    .Hcell {
      font-size: 1.1vw;
      font-weight: 600;
      text-align: center;
    }
  }
  .body {
    .row {
      display: grid;
      grid-template-columns: 7% 8% 7% 7% 7% 7% 7% 7% 7% 7% 7% 7% 8% 7%;
      border: 1px solid gray;
      padding: 2vh 0vw 2vh 0vw;
      .cell {
        font-size: 1vw;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}
.modaltable2 {
  width: 80%;
  position: fixed;
  top: 10vh;
  right: 11vw;
  min-height: 10vh;
  border-radius: 10px;
  max-height: 60vh;
  overflow-y: auto;
  border: 1px solid gray;
  margin-top: 10vh;
  box-shadow: rgb(145 158 171 / 24%) 0px 0px 2px 0px,
    rgb(145 158 171 / 24%) 0px 16px 32px -4px;
  .head {
    background-color: rgb(243, 198, 55);
    border: 1px solid black;
    display: grid;
    padding: 2vh 0vw 2vh 0vw;
    grid-template-columns: 7% 8% 7% 7% 7% 7% 7% 7% 7% 7% 7% 7% 8% 7%;
    .Hcell {
      font-size: 1.1vw;
      font-weight: 600;
      text-align: center;
    }
  }
  .body {
    .row {
      display: grid;
      grid-template-columns: 7% 8% 7% 7% 7% 7% 7% 7% 7% 7% 7% 7% 8% 7%;
      border: 1px solid gray;
      padding: 2vh 0vw 2vh 0vw;
      background-color: white;
      .cell {
        font-size: 1vw;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}
.table3 {
  min-height: 10vh;
  border-radius: 10px;
  border: 1px solid gray;
  margin-top: 10vh;

  .head {
    background-color: rgb(243, 198, 55);
    border: 1px solid black;
    display: grid;
    padding: 2vh 0vw 2vh 0vw;
    grid-template-columns: 7% 8% 7% 7% 7% 7% 7% 7% 7% 7% 7% 7% 8% 7%;
    .Hcell {
      font-size: 1.1vw;
      font-weight: 600;
      text-align: center;
    }
  }
  .body {
    .row {
      display: grid;
      grid-template-columns: 7% 8% 7% 7% 7% 7% 7% 7% 7% 7% 7% 7% 8% 7%;
      border: 1px solid gray;
      padding: 2vh 0vw 2vh 0vw;
      .cell {
        font-size: 1vw;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}
.nameTable {
  min-height: 10vh;
  border-radius: 10px;
  border: 1px solid gray;
  margin-top: 20px;
  
  .head {
    background-color: rgb(243, 198, 55);
    border: 1px solid black;
    display: grid;
    padding: 2vh 0vw 2vh 0vw;
    grid-template-columns: 10% 8% 8% 8% 8% 8% 8% 8% 8% 10% 8% 8%;
    .Hcell {
      font-size: 16;
      font-weight: 600;
      text-align: center;
    }
  }
  .body {
    .row {
      display: grid;
    grid-template-columns: 10% 8% 8% 8% 8% 8% 8% 8% 8% 10% 8% 8%;

      border: 1px solid gray;
      padding: 2vh 0vw 2vh 0vw;
      background-color: white;
      .cell {
        font-size: 16;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}
.modalnameTable {
  width: 90%;
  position: fixed;
  top: 28%;
  right: 52%;
  margin: auto;
  min-height: 10vh;
  border-radius: 10px;
  max-height: 60vh;
  overflow-y: auto;
  box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;
  outline: 100vh solid #00000091;
  transform: translateX(50%);
  .head {
    background-color: rgb(243, 198, 55);
    border: 1px solid black;
    display: grid;
    padding: 2vh 0vw 2vh 0vw;
    grid-template-columns: 10% 8% 8% 8% 8% 8% 8% 8% 8% 10% 8% 8%;
    .Hcell {
      font-size: 1.1vw;
      font-weight: 600;
      text-align: center;
    }
  }
  .body {
    .row {
      display: grid;
    grid-template-columns: 10% 8% 8% 8% 8% 8% 8% 8% 8% 10% 8% 8%;

      border: 1px solid gray;
      padding: 2vh 0vw 2vh 0vw;
      background-color: white;
      .cell {
        font-size: 1vw;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}
.modaltable3 {
  width: 80%;
  position: fixed;
  top: 10vh;
  right: 11vw;
  min-height: 10vh;
  border-radius: 10px;
  max-height: 60vh;
  overflow-y: auto;
  border: 1px solid gray;
  margin-top: 10vh;
  box-shadow: rgb(145 158 171 / 24%) 0px 0px 2px 0px,
    rgb(145 158 171 / 24%) 0px 16px 32px -4px;

  .head {
    background-color: rgb(243, 198, 55);
    border: 1px solid black;
    display: grid;
    padding: 2vh 0vw 2vh 0vw;
    grid-template-columns: 7% 8% 7% 7% 7% 7% 7% 7% 7% 7% 7% 7% 8% 7%;
    .Hcell {
      font-size: 1.1vw;
      font-weight: 600;
      text-align: center;
    }
  }
  .body {
    .row {
      display: grid;
      grid-template-columns: 7% 8% 7% 7% 7% 7% 7% 7% 7% 7% 7% 7% 8% 7%;
      border: 1px solid gray;
      padding: 2vh 0vw 2vh 0vw;
      background-color: white;
      .cell {
        font-size: 1vw;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}
.table4 {
  min-height: 10vh;
  border-radius: 10px;
  border: 1px solid gray;
  margin-top: 10vh;
  .head {
    background-color: rgb(243, 198, 55);
    border: 1px solid black;
    display: grid;
    padding: 2vh 0vw 2vh 0vw;
    grid-template-columns: 8% 10% 8% 8% 8% 8% 8% 8% 8% 8% 10% 8%;
    .Hcell {
      font-size: 1.1vw;
      font-weight: 600;
      text-align: center;
    }
  }
  .body {
    .row {
      display: grid;
      grid-template-columns: 8% 10% 8% 8% 8% 8% 8% 8% 8% 8% 10% 8%;
      border: 1px solid gray;
      padding: 2vh 0vw 2vh 0vw;
      .cell {
        font-size: 1vw;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}
.table5 {
  min-height: 10vh;
  border-radius: 10px;
  border: 1px solid gray;
  margin-top: 10vh;

  .head {
    background-color: rgb(243, 198, 55);
    border: 1px solid black;
    display: grid;
    padding: 2vh 0vw 2vh 0vw;
    grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
    .Hcell {
      font-size: 1.1vw;
      font-weight: 600;
      text-align: center;
    }
  }
  .body {
    .row {
      display: grid;
      grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
      border: 1px solid gray;
      padding: 2vh 0vw 2vh 0vw;
      .cell {
        font-size: 1vw;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}
.modaltable5 {
  width: 80%;
  position: fixed;
  top: 10vh;
  right: 11vw;
  min-height: 10vh;
  border-radius: 10px;
  max-height: 60vh;
  overflow-y: auto;
  border: 1px solid gray;
  margin-top: 10vh;
  box-shadow: rgb(145 158 171 / 24%) 0px 0px 2px 0px,
    rgb(145 158 171 / 24%) 0px 16px 32px -4px;

  .head {
    background-color: rgb(243, 198, 55);
    border: 1px solid black;
    display: grid;
    padding: 2vh 0vw 2vh 0vw;
    grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
    .Hcell {
      font-size: 1.1vw;
      font-weight: 600;
      text-align: center;
            border: 1px solid;

    }
  }
  .body {
    .row {
      display: grid;
      grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
      border: 1px solid gray;
      padding: 2vh 0vw 2vh 0vw;
      background-color: white;
      border: 1px solid;
      .cell {
        font-size: 1vw;
        font-weight: 600;
        text-align: center;
        border: 1px solid;
      }
    }
  }
}

.title {
  background-color: #8c4312;
  padding: 10px;
  font-size: 18px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  color: white;
  text-align: center;
}
.arrowHolder {
  text-align: center;
  font-size: 30px;
  background-color: white;
  svg {
    cursor: pointer;
    margin: 0vh 1vw 0vh 1vw;
  }
}
