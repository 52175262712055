.regholder {
  padding: 1vh 2vw 1vh 2vw;

  background: linear-gradient(
    hsl(47, 96%, 10%),
    hsl(52, 90%, 40%),
    #fad90284
  ) !important;
  box-shadow: rgb(0 0 0 / 60%) 0px 4px 8px, rgb(0 0 0 / 50%) 0px 10px 20px -5px,
    rgb(0 0 0 / 30%) 0px -4px 0px inset;
  border-radius: 30px;
  /* transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out; */
}

.regholder:hover {
  transform: scale(1.02);
  background-color: #f0c514;
}

.btn {
  background: linear-gradient(#070707, #353434 50%, #070707) !important;
  box-shadow: #00000066 0px 2px 4px, #0000004d 0px 7px 13px -3px,
    #00000033 0px -3px 0px inset;
  color: #fff !important;
  font-weight: 600 !important;
  height: 7vh !important;
  border-radius: 10px !important;
  transition: background-color 0.5s ease-in-out, color 1s ease-in-out;
}

.btn:hover {
  /* transform: translateY(2px) scale(1.05); */
  border: 1px solid #353434;
  color: #ddd;
}

.removeBtn {
  background-color: rgb(196, 58, 58);
  color: #fff;
  border: 0;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.removeBtn:hover {
  background-color: rgb(131, 10, 10);
}

.tableBtn {
  background-color: rgb(251, 191, 5);
  color: #000;
  border: 0;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s ease-in-out;
}

.tableBtn:hover {
  background-color: rgb(205, 161, 32);
}

.regholderLogin {
  padding: 1vh 2vw 1vh 2vw;
  background: linear-gradient(#f4c005, #f3d715, #fad902) !important;
  border-radius: 30px;
}

.btnLogin {
  background: linear-gradient(#020101, #7e211f 50%, #510c0f) !important;
  box-shadow: #02010166 0px 2px 4px, #0201014d 0px 7px 13px -3px,
    #02010133 0px -3px 0px inset;
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 18px;
  height: 7vh !important;
  border-radius: 10px !important;
  transition: background-color 0.5s ease-in-out, color 1s ease-in-out;
}

.btnLogin:hover {
  /* transform: translateY(2px) scale(1.05); */
  border: 2px solid #943230;
  color: #ddd;
}

.boxLogin {
  margin: 0 auto;
  padding: 0.4em;
  width: fit-content;
  /* background: #1c1f2b; */
  text-align: center;
  border-radius: 30px;
  position: relative;
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

.boxLogin::after,
.boxLogin::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  /* background-image: conic-gradient(
    from var(--angle),
    transparent 70%,
    #370b0a,
  ); */
  background-image: conic-gradient(
    from var(--angle),
    #370b0a,
    #e1543f,
    #370b0a,
    #e1543f
  );
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  z-index: -1;
  padding: 3px;
  border-radius: 30px;
  animation: 3s spin linear infinite;
}
.boxLogin::before {
  filter: blur(1.5rem);
  opacity: 0.5;
}
@keyframes spin {
  from {
    --angle: 0deg;
  }
  to {
    --angle: 360deg;
  }
}

/* linear-gradient(#020101, #7e211f 50%, #510c0f) !important */
