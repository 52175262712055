.tableContainer {
  max-width: 85%;
  margin: auto;
  overflow: auto;
}

.main {
  background-color: #ffffff;
  width: 850px;
  margin: 10vh auto auto auto;
  border-radius: 5px;
  box-shadow: rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px;
  .row1 {
    padding: 1vh 0vw 2vh 0vw;
    display: flex;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    background-color: #f3c637;
    color: #000;
    font-weight: 600;
    font-size: 20px;
    .head {
      text-align: center;
    }
  }
  .row2 {
    padding: 3vh 0vw 3vh 0vw;
    display: flex;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    .cell {
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }
  }
}
.total {
  border-radius: 5px;
  margin: 10vh auto 0vh auto;
  width: 50%;
  background-color: #ffffff;
  text-align: center;
  .head {
    background-color: #f3c637;
    padding: 2vh 2vw 2vh 2vw;
    color: #000;
    font-size: 20px;
    font-weight: 800;
  }
  .body {
    padding: 0vh 2vw 3vh 2vw;
    font-size: 18px;
    font-weight: 800;
  }
}