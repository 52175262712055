.contain {
  position: relative;
  .inpContain {
    position: relative;
    .inp {
      background-color: #e8e8e8;
      height: 3rem;
      border-radius: 3px;
      border: 0;
      color: rgb(166, 162, 157);
      font-size: 1rem;
      font-weight: bold;
      padding-left: 4rem;
      padding-right: 10px;
      border-bottom: 1px solid black;
      outline: none;
      &:focus {
        border-bottom: 2px solid black;
      }
    }
    .inp::placeholder {
      color: rgb(166, 162, 157);
      font-weight: 400;
    }
    .icon {
      position: absolute;
      top: 10px;
      left: 10px;
      font-size: 20px;
      cursor: pointer;
      color: rgb(250, 201, 0);
    }
    .icon2 {
      display: none;
      position: absolute;
      top: 16px;
      left: 2rem;
      font-size: 10px;
      cursor: pointer;
      color: rgb(250, 201, 0);
    }
    &:hover {
      .icon2 {
        display: block;
      }
    }
  }
  .dropContainer {
    max-height: 15rem;
    position: absolute;
    top: 3.5rem;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #e8e8e8;
    width: 100%;
    p {
      margin: 0;
      font-size: 15px;
      font-weight:400;
      padding: 8px 14px;
      cursor: pointer;
      &:hover {
        background-color: #f3c637;
      }
    }
    z-index: 99999;
  }
}
