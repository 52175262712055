.debtDetails {
  padding: 1rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 2vh;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  div {
    margin: 10px;
  }
  h6 {
    margin-top: 1vh;
  }
  .truck {
    justify-content: end;
    gap: 8px;
    display: flex;
  }
  .advance {
    justify-content: start;
    display: flex;
    gap: 8px;
  }
  .sellinvoice {
    justify-content: end;
    display: flex;
    gap: 8px;
   
  }
  .borrow {
    justify-content: start;
    display: flex;
    gap: 8px;
    margin-right: 5rem;
  }
}
.grid2 {
  padding: 1rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 2vh;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  h6 {
    margin-top: 1vh;
  }
  .tool {
    justify-content: end;
    display: flex;
    gap: 8px;
    margin-right: 3rem;
  }
  .salary {
    justify-content: start;
    display: flex;
    gap: 8px;
  }
  .morta {
    justify-content: start;
    display: flex;
    gap: 8px;
  }
}
.grid21 {
  width: 70%;
  padding: 1vw;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 2vh;
  display: grid;
  grid-template-columns: auto;
  h6 {
    margin-top: 1vh;
  }
  .tool {
    display: flex;
    gap: 8px;
    margin: 1vh 3vw 0vh 18vw;
  }
}
.root {
  flex-grow: 1;
  width: "100%";
  height: "100%";
}
