.footer1 {
  
  font-size: 11px;
  padding: 0.5vh 2vw .5vh 2vw;
  background-color: #f3c637;
  font-weight: bolder;
  border-radius:0px 0px 0px 100px;
  text-align: center;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer2 {
  font-size: 11px;
  text-align: center;
  width: 50%;
  font-weight: bolder;
  padding: 0.5vh 2vw .5vh 2vw;
  background-color: #ec9d3a;
  border-radius:0px 100px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

