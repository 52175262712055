.nameAndDate {
  background: #ffffff;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  display: flex;
  width: 100%;
}
.fixed {
  position: fixed;
  top: 0;
}
.top1 {
  margin-top: 5rem;
}
.arrowUPTo {
  background: #ffa500;
  height: 48px;
  width: 48px;
  border-radius: 33px;
  cursor: pointer;
  box-shadow: rgb(0 0 0 / 56%) 0px 22px 70px 4px;
  transform: rotate(270deg);
  color: white;
  position: absolute;
  right: -19.5px;
}
.printTable-small .MuiTableCell-head {
  color: #182933;
  font-weight: 500;
  line-height: 1.5rem;
  font-size: 1.8vw;
  height: 100%;
  padding: 0;
  line-height: 3;
}
.printTable .MuiTableCell-head {
  color: #182933;
  font-weight: 500;
  line-height: 1.5rem;
  font-size: "14px !important";
  height: 100%;
  padding: 0;
  line-height: 2;
}

.printTable-small .MuiTableCell-body {
  color: #182933;
  font-size: 1.8vw;
}
.printTable .MuiTableCell-body {
  color: #182933;
  font-size: "14px !important";
  line-height: 2;
}
.cell-table-size {
  font-size: "2vmin !important";
}

@media only screen and (max-width: 700px) {
  .nameAndDate {
    width: 90%;
  }
}
@media only screen and (max-width: 1000px) {
  .arrowUPTo {
    height: 33px;
    width: 33px;
    right: -18.5px;
  }
}
.marginHolder {
  margin-top: 3rem;
}
@media (max-width: 468px) {
  .marginHolder {
    margin-top: 6rem;
  }
}
