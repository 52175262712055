.MuiInput-underline:before {
    border-bottom: 0 solid rgba(0, 0, 0, 0.42) !important;
    pointer-events: none;
}

.headerSearch
{
    background-color: white;
    padding: 0 20px 20px 20px;
    border-radius: 15px;
    box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
    margin: 50px auto;
}
