.avatar {
  color: white !important;
  margin: 0.5vw;
  border: white solid 3px;
}
.user {
  color: black;
  margin-left: 1vw !important;
}
.avatar :hover {
  color: black;
  width: 50px;
  height: 30px;
}

.exit {
  width: 50px;
  height: 30px;
  color: black;
  background-color: transparent;
  border-radius: 50%;
}
.exit:hover {
  color: red;
  /* background: #ffffff7b; */
}
.exit svg {
  font-size: 2.5vw !important;
}
.exit img {
  width: 50px;
}
.makeStyles-drawerPaper-7 {
  overflow: visible !important;
}
.mainholder {
  display: block;

  /* width: 20vw; */
}
.owlLOGOBg {
  position: relative;
  
  display: flex;
  justify-content: center;
  margin-top: 10px;

  height: 20vw;
}
.owlLOGOBg img {
  position: absolute;
  width: 20vw;
}
.owlimg {
  max-width: 14vw;
  margin: 1vh 1vw 0vh 0vw;
}

.owlLOGO {
  position: relative;
  max-width: 14vw;
  height: 14vw;
}

.owlLOGO img {
  position: absolute;
  max-width: 13vw;
}

.owlEyes {
  animation: moveEyes 15s infinite;
}

.owlLeftEar {
  animation: moveLeftEar 6s ease-in-out infinite alternate;
}
.owlRightEar {
  animation: moveRightEar 6s ease-in-out infinite alternate;
}

.owlTop {
  animation: moveTop 5s ease-in-out infinite alternate;
}

.owlBottom {
  animation: moveBottom 5s ease-in-out infinite alternate;
}

@keyframes moveEyes {
  0%,
  2%,
  10%,
  40% {
    transform: scaleY(1);
  }
  0.5%,
  10.5%,
  40.8% {
    transform: scaleY(0.1);
  }
  3%,
  12%,
  42%,
  100% {
    transform: scaleY(1);
  }
}

@keyframes moveLeftEar {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes moveRightEar {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes moveTop {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes moveBottom {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(3deg);
  }
  75% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(0);
  }
}
#backgroundLogin {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  animation: moveBackground 60s ease-in-out infinite alternate;
  background: linear-gradient(
      120deg,
      rgba(22, 86, 87, 0.3),
      rgba(142, 106, 30, 0.3)
    ),
    url("../assets/images/LoginBg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4), 0 6px 6px rgba(0, 0, 0, 0.2);
  z-index: -10;
  overflow: hidden;
}

@keyframes moveBackground {
  0% {
    background-position: center;
    transform: scale(1) rotate(0deg);
  }
  25% {
    transform: scale(1.05) rotate(2deg) translate(1%, 1%);
  }
  50% {
    background-position: bottom right;
    transform: scale(1.1) rotate(-2deg) translate(-1%, -1%);
  }
  75% {
    transform: scale(1.05) rotate(2deg) translate(1%, 1%);
  }
  100% {
    background-position: center;
    transform: scale(1) rotate(0deg);
  }
}

.status {
  margin: -3vh 4.5vw 1vh 0vw;
  font-size: 14px;
  padding: 1px 30px;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px,
    rgb(0 0 0 / 20%) 0px -3px 0px inset;
}
.online {
  color: #062a0a;
  background: linear-gradient(to top, #008000ac, #23c023a6, #008000ac);
}

.offline {
  color: #260202;
  background: linear-gradient(to top, #f40505ac, #f90303a6, #f40505ac);
}

.rightIcon {
  font-size: 2vw;
  margin: 1vh 0vw 0vh 2vw;
  color: rgba(0, 0, 0, 0.7);
}
.rightIcon:hover {
  background: #1d1c1c11;
  border-radius: 50%;
}
.iconholder {
  display: flex;
  background: #fff;
  padding: 0vh 0vw 0vh 0vw;
  margin: 0vh 0vw 0vh .5vw;
}

.iconholderclosed {
  display: none;
  height: 0;
  width: 0;
}
.disabled {
  background-color: transparent;
}

@media (max-width: 1600px) {
  .status {
    font-size: 12px;
    padding: 1px 25px;
    margin: -2.5vh 4.8vw 1vh 0vw;
  }
}
@media (max-width: 1400px) {
  .status {
    font-size: 12px;
    padding: 1px 25px;
    margin: -1.8vh 4.5vw 1vh 0vw;
  }
}
@media (max-width: 1200px) {
  .status {
    font-size: 12px;
    padding: 1px 25px;
    margin: -1.2vh 3.5vw 1vh 0vw;
  }
  .owlLOGOBg {
    position: relative;
    margin: auto;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    height: 29vw;
  }
  .owlLOGOBg img {
    position: absolute;
    width: 29vw;
  }
}

@media (max-width: 992px) {
  .status {
    font-size: 10px;
    padding: 1px 20px;
    margin: -1.3vh 3.5vw 1vh 0vw;
  }
  .owlLOGOBg {
    position: relative;
    margin: auto;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    height: 35vw;
  }
  .owlLOGOBg img {
    position: absolute;
    width: 35vw;
  }
}

@media (max-width: 768px) {
  .avatar {
    margin: 3vw 0vw;
  }
  .status {
    font-size: 8px;
    padding: 1px 15px;
    margin: -1vh 4vw 0.5vh 0vw;
  }
  .owlLOGOBg {
    position: relative;
    margin: auto;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    height: 40vw;
  }
  .owlLOGOBg img {
    position: absolute;
    width: 40vw;
  }
}

@media only screen and (max-width: 576px) {
  .avatar {
    margin: 3vw 0vw;
    width: 30px !important;
    height: 30px !important;
  }
  .user {
    font-size: 3vw !important;
  }

  .exit img {
    margin-top: 1.5px;
    width: 42px;
  }

  .owlimg {
    max-width: 20vw;
    height: 20vw;
    margin: 1vh 2vw 1vh 0vw;
  }

  .owlLOGO img {
    position: absolute;
    max-width: 20vw;
  }
  .owlLOGOBg {
    position: relative;
    margin: auto;
    display: flex;
    justify-content: center;
    margin-top: 50px;

    height: 60vw;
  }
  .owlLOGOBg img {
    position: absolute;
    width: 60vw;
  }

  .status {
    font-size: 8px;
    padding: 1px 10px;
    margin: -1vh 7vw 0.5vh 0vw;
  }

  .disabled {
    width: 0;
    height: 0;
    overflow: hidden;
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .avatar {
    margin: 3vw 0vw;
  }
  .status {
    font-size: 6px;
    padding: 1px 10px;
    margin: -1vh 7vw 0.5vh 0vw;
  }
  .owlLOGOBg {
    position: relative;
    margin: auto;
    display: flex;
    justify-content: center;
    margin-top: 50px;

    height: 70vw;
  }
  .owlLOGOBg img {
    position: absolute;
    width: 70vw;
  }

}
@media only screen and (max-width: 290px) {
  .user {
    display: none;
  }

  .status {
    font-size: 6px;
    padding: 1px 10px;
    margin: 0vh 3vw 0.5vh 0vw;
  }
}
